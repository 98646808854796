body {
  box-sizing: border-box;
  background-color: #f1f1ee;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

html {
  scroll-behavior: smooth;
}
