* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.container {
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 1em;
  height: 100vh;
}

// .outer-gallery {
//   width: 100vw;
//   height: 100vw;
//   transform: rotate(-90deg) translateX(-100vh);
//   transform-origin: top left;
//   overflow-y: scroll;
//   overflow-x: hidden;
//   // position: absolute;
// }

.gallery {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  // transform: rotate(90deg) translateY(-100vh);
  // transform-origin: top left;
}

img {
  width: 400px;
  height: auto;
  object-fit: contain;
}

.portrait {
  margin: 0.5em;
}

.landscape {
  margin: 1em;
}

.about {
  padding: 1em;
}

.about p {
  font-family: neue;
  color: #000;
  opacity: 1;
  line-height: 1.5em;
}
/*font faces*/
@font-face {
  font-family: Antonia;
  src: url(../public/fonts/Antonia/AntoniaH1-Light.ttf);
}
@font-face {
  font-family: Voyage;
  src: url(../public/fonts/Voyage-Regular.otf);
}
@font-face {
  font-family: neue;
  src: url(../public/fonts/HKGrotesk-Regular.otf);
}

.header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav {
  display: flex;
  align-items: center;
  width: auto;
  font-family: Voyage;
  font-size: 24px;
  background-color: transparent;
}

.parag {
  box-sizing: border-box;
  margin: 2em;
}

.link {
  box-sizing: border-box;
  padding: 0.5em;
  border-radius: 2px;
  text-decoration: none !important;
  color: #000;
  margin-left: 20px;
  border: 0.5px solid #000;
  font-family: neue;
  text-transform: uppercase;
  font-size: 16px;
}

.line {
  border: 0.5px solid #000;
  width: 100%;
}
.footer {
  text-transform: lowercase;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  font-family: neue;
  text-transform: uppercase;
  background-color: transparent;
}
button {
  display: inline-block;
  background: transparent;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  width: 10vw;
  text-transform: uppercase;
  padding: 0.5em;
  margin: 1em;
  border: 1px solid #000;
  border-radius: 30px;
  cursor: pointer;
}
.b-button:hover .b-link__inner {
  transform: translateY(-100%);
}

hr {
  border-bottom: 1px solid black;
}

h1 {
  font-family: Voyage;
  color: #000;
  font-size: 3vw;
  font-weight: normal;
  margin: 0;
}

h3 {
  font-size: 12px;
  text-transform: uppercase;
  font-family: GT;
  font-weight: lighter;
}

h4 {
  font-family: Antonia;
  color: #000;
  font-size: 1.5em;
  font-weight: lighter;
}

li {
  list-style-type: none;
  margin-left: 20px;
}

ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  opacity: 0;
  transform: translate(0, 50px);
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5em;
  }
  button {
    width: 70px;
  }
  img {
    width: 100%;
    height: auto;
    padding-top: 30px;
    object-fit: contain;
    box-sizing: border-box;
  }
  .container {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0.5em;
  }

  .portrait {
    margin: 0;
  }

  .gallery {
    flex-direction: column;
  }

  .footer {
    flex-direction: column;
    text-align: left;
  }

  .parag {
    margin: 1em;
  }
}
